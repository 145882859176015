<template>
<div>
   <v-btn @click="Editing = !Editing" fab right absolute top
    v-if="CanEdit">
      <v-icon :color="Editing ? 'red': ''" >{{Editing? 'mdi-cancel' : 'mdi-pencil'}}
      </v-icon>
    </v-btn>
      <v-btn style="margin-right: 60px;" v-if="Editing" @click="SaveRecord({Record: Record,Fields: [field]})" fab right absolute top
     > 
      <v-icon color="success" >mdi-content-save
      </v-icon>
    </v-btn>
   <v-list-item class="justify-center subtleoverline"> 
        {{field.DisplayName}}
    </v-list-item>
     <v-card-title v-if="!Editing && field.id === 'Owner' || !Editing && !field.id" class="justify-center mediumoverline">
      {{Record[CurrentEntity.Primary_Field_Name]}}
    </v-card-title>
     <TextField :col="col" v-if="Editing && field.id === 'Owner' || Editing && !field.id"
      :Record="Record" :CanEdit="CanEdit" :Editing="Editing"
      :System="System" :SystemEntities="SystemEntities" :field="CurrentEntity.RecordPrimaryField"
      />
  <v-layout class="justify-center" v-if="!Editing">
   
    <img v-if="FieldValue"
    height="110px"
      style="border-radius: 50%;"
      :src="
        FieldValue.Profile_Photo
          ? FieldValue.Profile_Photo
          : require('@/assets/BlankProfilePic.png')
      "
    />
  </v-layout>
   <UserLookup :UsersLookupArray="field.Array"  :ModelProp="field.Name" :RecordObj="Record" @UpdateUserLookupProp="UpdateUserLookupProp"
   v-if="Editing && field.id"
    :FieldLabel="field.DisplayName" :ReadOnly="field.ReadOnly || Record[field.Name] && !UserCanGetLookup" :Rules="field.IsMandatory? [$store.state.formrules.required] : []"
    />
  <v-list-item class="justify-center" v-if="FieldValue">
    <v-list-item-content>
      <v-list-item-title style="text-align: center;">
    {{FieldValue.Full_Name}}
      </v-list-item-title>
      <v-list-item-subtitle style="text-align: center;">
        <a :href="'mailto:'+FieldValue.Email" target="_top">{{FieldValue.Email}}</a>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
  <v-list-item class="justify-center" v-if="!FieldValue && field.id && !Editing">
     NO DATA
  </v-list-item> 
  <v-list-item v-if="Record.Business_Unit" class="justify-center">
     <v-btn outlined style="width: 100%;" depressed>
    {{Record.Business_Unit.Name}}
    </v-btn>
  </v-list-item>
  <!-- <v-list-item v-if="CanEdit && field && !field.ReadOnly" class="justify-center">
    <v-btn style="width: 100%;" dark color="warning">
      Change
    </v-btn>
  </v-list-item> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import UserLookup from '@/components/General/UserLookup'
import TextField from '@/components/SuiteBuilder/SuiteApps/Dashboards/FieldGroups/TextField'
export default {
    props: ['System','SystemEntities','col','Record','CanEdit','RelatedObj','CurrentEntity'],
    components: {UserLookup,TextField},
    data() {
        return {
          Editing: false,
        }
    },	
    computed:{
      field(){
        return this.col.FieldObj
    },
      FieldValue(){
        return this.Record && this.Record[this.col.Field.id] ? 
        this.Record[this.col.Field.id] : 
        ''
      },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        SaveRecord(payload){
          this.$emit('SaveRecord',payload)
          this.Editing = false
      },
      UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
            if(Prop === 'Owner'){
              //yes need to but put off for testing this.AssignBU()
            }
        },
      AssignBU(){
      let userid = this.Record.Owner.id
      let ownerobj = {
        id: this.Record.Owner.id,
        Full_Name: this.Record.Owner.Full_Name,
        Name: this.Record.Owner.Name,
        Surname: this.Record.Owner.Surname
      }
      this.CreateLiaisonNotificationtoOwner(this.Record)
      db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('userroles').doc(userid).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        let BUId = userdata.Business_Unitid
        db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessunits').doc(BUId).onSnapshot(snapshot => {
        let BUdata = snapshot.data()
        let buobj = {
          id: BUId,
          Name: BUdata.Name
        }
        this.ItemOperationalDB.update({
          Owner: ownerobj,
          Ownerid: ownerobj.id,
          Business_Unit: buobj,
          Business_Unitid: buobj.id
        })        
      })
      })
    },
    CreateLiaisonNotificationtoOwner(record){
        let owneruser = record.Owner
        let NewComms = {
          description: this.userLoggedIn.Full_Name+' assigned the record to '+owneruser.Full_Name,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.CurrentEntity.DisplayName,
          recordlocation: '/'+this.CurrentEntity.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.CurrentEntity.id,
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        //console.log(NewComms)
        this.SaveNewcomms(NewComms,record)  
    },
    SaveNewcomms(NewComms,record){      
      //db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(record.id).collection('InternalLiaison')
        let commscol = this.ItemOperationalDB.collection('InternalLiaison')
        //console.log(commscol)
        let owneruser = record.Owner
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.CurrentEntity.Primary_Field_Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.CurrentEntity.Primary_Field_Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    //console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },

    }
}
</script>

<style>

</style>



